<template>
  <svg aria-hidden="true">
    <use :xlink:href="symbolId" />
  </svg>
</template>

<script setup lang="ts">
const props = withDefaults(defineProps<{ name: string }>(), {
  name: 'contract-report'
})

const symbolId = computed(() => `#icon-${props.name}`)
</script>

<style scoped></style>
